<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <a-tooltip placement="top">
            <template slot="title"><span>Add Admin Scenario Accessory</span></template>
            <a href="javascript: void(0);" class="btn btn-sm btn-success float-right mb-1" v-b-modal.add-update-admin-scenario-accessory-modal @click="$bvModal.show('add-update-admin-scenario-accessory-modal'), resetForm()">
              <i class="fa fa-plus"/> Add Admin Scenario Accessory
            </a>
          </a-tooltip>
          <table class="table table-sm table-bordered text-center" id="admin_scenario_accessory_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(admin_scenario_accessory, index) in admin_scenario_accessories" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ admin_scenario_accessory.name }}</td>
              <td>
                <span v-if="admin_scenario_accessory.status === 1"><a-tag color="green">Active</a-tag></span>
                <span v-else><a-tag color="red">Inactive</a-tag></span>
              </td>
              <td>
                <a class="btn btn-sm btn-primary mr-2" href="javascript: void(0);" @click.prevent="edit(admin_scenario_accessory.id), $bvModal.show('add-update-admin-scenario-accessory-modal')"><i class="fe fe-edit mr-1"></i>Update</a>
                <a class="btn btn-sm btn-danger mr-2" href="javascript: void(0);" @click.prevent="onDelete(admin_scenario_accessory.id)"><i class="fa fa-trash mr-1"></i>Delete</a>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="admin_scenario_accessories.length === 0 && !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
        </div>
      </div>
      <add-update-accessory
        :admin_scenario_accessory="admin_scenario_accessory"
        :btnEdit="btnEdit"
        @getAdminScenarioAccessories="getAdminScenarioAccessories"
        @resetForm="resetForm"
        ref="addUpdateAccessory">
      </add-update-accessory>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import addUpdateAccessory from '@/views/adminScenario/accessories/modal/addUpdateAccessory'

export default {
  name: 'List',
  components: {
    Empty,
    addUpdateAccessory,
  },
  data() {
    return {
      admin_scenario_accessories: [],
      admin_scenario_accessory: {},
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getAdminScenarioAccessories()
  },
  methods: {
    getAdminScenarioAccessories() {
      this.flag = true
      this.loading = true
      this.loader = true
      apiClient.get('api/admin-scenario/accessories')
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.admin_scenario_accessories = response.data.admin_scenario_accessories
        })
        .catch(error => {
          console.log(error)
        })
    },
    edit(id) {
      this.admin_scenario_accessory = {}
      this.btnEdit = true
      apiClient.get('api/admin-scenario/accessories/' + id + '/edit').then(response => {
        if (!response.data.error) {
          this.admin_scenario_accessory = response.data.admin_scenario_accessory
        }
      }).catch(error => {
        console.log(error)
      })
    },
    onDelete(Id) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/admin-scenario/accessories/' + Id).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              this.getAdminScenarioAccessories()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    resetForm() {
      this.btnEdit = false
      this.admin_scenario_accessory = {}
    },
  },
}
</script>

<style scoped>

</style>
